<template>
  <div class="content">
    <div class="banner">
      <img
        src="@/assets/images/home_banner.png"
        alt=""
        srcset=""
        class="home_banner"
      >
      <div
        class="btn banner-btn"
        @click="handleToWork()"
      >立即沟通</div>
    </div>
    <div class="banner">
      <div class="title">
        师资匹配率最高可达99%，极大降低退费率
      </div>
      <div class="banner-main">
        <div class="main-left">
          <img
            src="@/assets/images/sammy_banner.png"
            alt=""
            srcset=""
          >
        </div>
        <div class="main-right">
          <div
            class="item"
            v-for="(item,index) in text1Data"
            :key="index"
          >
            <div class="item-top">{{item.top_text}}</div>
            <div class="item-bottom">{{item.bottom_text}}</div>
          </div>
          <div
            class="btn ex-btn"
            @click="handleToWork()"
          >
            免费体验
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="title">
        零匹配成本以及低于市场价的课时费，提高课程利润率
      </div>
      <div class="banner-main bcg-image">
        <div class="half-wrap">
          <div class="half-title">
            当前的匹配现状
          </div>
          <div class="half-main">
            <div class="item">
              <div class="item-top">额外的推荐费用</div>
              <div class="item-bottom">300-500元不等</div>
            </div>
            <div class="item">
              <div class="item-top">高额的课时费用</div>
              <div class="item-bottom">北上广深院校：180元/小时起</div>
              <div class="item-bottom">985/211院校：200元/小时起</div>
              <div class="item-bottom">艺术类专业：300元/小时起</div>
              <div class="item-bottom">其他冷门专业：250元/小时起</div>
            </div>
          </div>
        </div>
        <div class="half-wrap">
          <div class="half-title">
            我们的服务优势
          </div>
          <div class="half-main half-main-blue">
            <div class="item item-bule">
              <div class="item-top">推荐费用</div>
              <div class="item-bottom">
                <span>0元</span>
              </div>
            </div>
            <div class="item item-bule">
              <div class="item-top">课时费用</div>
              <div class="item-bottom">
                <span>远低于市场的课时费</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="title">
        师资匹配率最高可达99%，极大降低退费率
      </div>
      <div class="banner-main">
        <!-- 左右位置变了一下 -->
        <div class="main-right">
          <div
            class="item"
            v-for="(item,index) in text2Data"
            :key="index"
          >
            <div class="item-top">{{item.top_text}}</div>
            <div class="item-bottom">{{item.bottom_text}}</div>
          </div>
          <div
            class="btn ex-btn"
            @click="handleToWork()"
          >
            免费体验
          </div>
        </div>
        <div class="main-left">
          <img
            src="@/assets/images/banner_img.png"
            alt=""
            srcset=""
          >
        </div>
      </div>
    </div>
    <div class="transition-banner">
      <div class="text-wrap1">
        可适当减少班主任人员配置，降低课程服务成本
      </div>
      <div class="text-wrap2">
        以您机构的名义，承接学员全程的专业课课程安排及监督，分配专属的学管师与学生沟通对接，全方面的保障学员的课程进度及质量；匹配、对接、授课、回访、督学…完善的内容及服务形态，一站式解决您的专业课全链路流程。
      </div>
      <div
        class="btn tr-btn"
        @click="handleToWork()"
      >
        免费体验
      </div>
    </div>
    <div class="banner">
      <div class="title">
        他们都在用研盒
        <span>更好地服务，铸就更好地信任</span>
      </div>
      <div class="count-wrap">
        <div
          class="count-item"
          v-for="(item,index) in text3Data"
          :key="index"
        >
          <span class="top">{{item.top_text}}+</span>
          <span class="bottom">{{item.bottom_text}}</span>
        </div>
      </div>
      <div class="company-img">
        <img
          src="@/assets/images/comcany_banner_bcg.png"
          alt=""
          sizes=""
          srcset=""
        >
      </div>
    </div>
    <CompanyChoose
      :visible="CompanyChooseDialog"
      :content="CompanyChooseArray"
      @close="CompanyChooseDialog=false"
    />
  </div>
</template>

<script>

import { setStorage, getStorage, crmUrl } from "@/utils/helper";
import CompanyChoose from '@/components/CompanyChoose.vue'
export default {
  components: {
    CompanyChoose
  },
  data() {
    return {
      CompanyChooseDialog: false,
      CompanyChooseArray: [],
      text1Data: [
        {
          top_text: "自主研发订单对接系统",
          bottom_text: "在线实时提交服务需求，一键即可查看详细服务记录"
        },
        {
          top_text: "师资覆盖超600所高校",
          bottom_text: "匹配需求提交后即可快速响应，极大缩短匹配周期"
        },
        {
          top_text: "满足个性化辅导需求",
          bottom_text: "海量的师资储备，总是可以满足您对课程的质量要求"
        },
        {
          top_text: "只招生不退费",
          bottom_text: "最高可达99%的匹配率，严格杜绝因无匹配而退费的情况"
        }
      ],
      text2Data: [
        {
          top_text: "完善的师资招募流程",
          bottom_text: "招募、筛选、培训、试讲等流程全面把控，严控课程质量"
        },
        {
          top_text: "超详细的课程计划定制",
          bottom_text: "课前制定超详细课程计划，时刻把控课程进度及内容"
        },
        {
          top_text: "课程内容实时回放",
          bottom_text: "多对一平台授课，全程监管，课后回放"
        },
        {
          top_text: "课后评价系统",
          bottom_text: "课后实时确认及评价系统，确保课程口碑"
        }
      ],
      text3Data: [
        {
          top_text: 600,
          bottom_text: "优质客户"
        }, {
          top_text: "10万",
          bottom_text: "服务学员"
        }, {
          top_text: "70万",
          bottom_text: "累计师资"
        }, {
          top_text: '95%',
          bottom_text: "匹配率"
        },
      ]
    };
  },
  created() {

  },
  computed: {
    isUser() {
      return getStorage('admin_user')
    }
  },
  methods: {
    handleToWork() {
      if (this.isUser) {
        let companyList = this.isUser.companys
        if (companyList.length == 1) {
          let str = crmUrl()
          window.open(`${str}/overview?userInfo=${JSON.stringify(this.isUser)}&cid=${companyList[0].id}`)
        } else {
          this.CompanyChooseArray = companyList
          this.CompanyChooseDialog = true
        }
      } else {
        this.$router.push(`/submit/register`)
      }

    }
  }

};
</script>

<style lang="less" scoped>
.content {
  padding: 0.48rem 0px;
  transition: 1s;

  .banner {
    width: 73.75%;
    position: relative;
    margin: 0 auto;
    .home_banner {
      width: 100%;
      left: 0px;
    }
    .banner-btn {
      position: absolute;
      left: 0px;
      bottom: 30.95%;
      width: 1.36rem;
      height: 0.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      margin-top: 1.2rem;
      margin-bottom: 0.56rem;
      font-size: 0.4rem;
      font-weight: 600;
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        margin-top: 0.16rem;
        font-size: 0.2rem;
        color: #999999;
        font-weight: 400;
      }
    }
    .banner-main {
      display: flex;
      width: 100%;

      .main-right {
        flex: 1;
        padding: 0.44rem 0px 0.44rem 0.7rem;
        .item {
          display: flex;
          flex-wrap: wrap;

          & + .item {
            margin-top: 0.2rem;
          }
          .item-top {
            background: url("https://upload.kaoyanbox.net/66138a204c2c8ae2f3e761fb79862cd9.png")
              no-repeat;
            background-size: 100% 100%;
            padding: 0px 0.06rem;
            font-weight: 600;
            font-size: 0.18rem;
            line-height: 0.25rem;
            margin-bottom: 0.16rem;
          }
          .item-bottom {
            font-size: 0.14rem;
            color: #999999;
            line-height: 0.22rem;
            padding: 0px 0.06rem;
            width: 100%;
          }
        }
        .ex-btn {
          width: 1.36rem;
          height: 0.48rem;
          margin-top: 0.48rem;
        }
      }
      .main-left {
        width: 8.88rem;
        height: 5.32rem;

        img {
          width: 100%;
        }
      }
    }
    .bcg-image {
      height: 5.11rem;
      background-image: url("../../assets/images/banner_bcg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      .half-wrap {
        flex: 1;
        .half-title {
          font-weight: 600;
          font-size: 0.3rem;
          color: #ffffff;
          margin-top: 0.43rem;
          text-align: center;
        }
        .half-main {
          margin-top: 0.43rem;
          padding: 0.4rem 0.86rem;
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            flex-wrap: wrap;

            & + .item {
              margin-top: 0.32rem;
            }
            .item-top {
              background: url("https://upload.kaoyanbox.net/7947ef60efa87e4fea6a0c0df77c16c1.png")
                no-repeat;
              background-size: 100% 100%;
              padding: 0px 0.06rem;
              font-weight: 600;
              font-size: 0.18rem;
              line-height: 0.25rem;
              margin-bottom: 0.16rem;
            }
            .item-bottom {
              & + .item-bottom {
                margin-top: 0.16rem;
              }
              font-size: 0.14rem;
              color: #999999;
              line-height: 0.22rem;
              padding: 0px 0.06rem;
              width: 100%;
            }
          }
          .item-bule {
            .item-top {
              background: url("https://upload.kaoyanbox.net/66138a204c2c8ae2f3e761fb79862cd9.png")
                no-repeat;
              background-size: 100% 100%;
            }
            .item-bottom {
              span {
                background: #83bcff;
                padding: 0.02rem 0.16rem;
                color: #fff;
              }
            }
          }
        }
        .half-main-blue {
          padding-left: 1.5rem;
        }
      }
    }
    .count-wrap {
      display: flex;
      justify-content: space-around;
      .count-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          font-size: 0.56rem;
          font-weight: 600;
        }
        .bottom {
          font-size: 0.3rem;
          font-weight: 600;
        }
      }
    }
    .company-img {
      margin-top: 0.64rem;
      img {
        width: 100%;
      }
    }
  }
  .transition-banner {
    background: url("https://upload.kaoyanbox.net/65757387db12341c02c38b49a666540e.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: 1.04rem;
    height: 4.03rem;
    padding: 0.56rem 4.28rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .text-wrap1 {
      text-align: center;
      font-size: 0.4rem;
      font-weight: 600;
      color: #ffffff;
    }
    .text-wrap2 {
      font-size: 0.18rem;
      color: #ffffff;
      line-height: 0.5rem;
    }
    .tr-btn {
      background-color: #fff;
      color: #1f86fe;
      width: 1.36rem;
      height: 0.48rem;
      margin: 0 auto;
    }
    .tr-btn:active {
      background: #f5f5f5;
    }
  }
}
.btn {
  background: #1f86fe;
  border-radius: 6px;
  font-size: 0.18rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:active {
  background: #79b6fe;
}
</style>
